<template>
  <v-dialog v-model="visible" max-width="1300px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ item.cardTitle }} </v-card-title>
      <v-card-text>
        <img class="imgSize" :src="item.thumbnail" />
        <div>
          <v-chip-group active-class="primary--text" column>
            <v-chip v-for="tag in item.hashTags" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Header from "./comp/Header.vue";
export default {
  components: { Header },
  props: {
    // fileLink: {
    //   type: String,
    //   default: '',
    // },
  },
  data() {
    return {
      visible: false,
      item: "",
    };
  },
  methods: {
    Open(item) {
      this.item = item;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
.imgSize {
  width: 100%;
}
</style>