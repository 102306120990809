<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <v-row>
        <v-col cols="12">
          <SelectBox
            label="Select Category"
            selectStyleWidth="width: 200px"
            :value="selectedCategory"
            :items="itemsCategory"
            :multiple="false"
            @changeSelect="changeSelectCategory"
          />
        </v-col>
        <v-col cols="12" class="_top">
          <WekNewCard :data="wekNewContents" @clickCard="clickCard" />
        </v-col>
      </v-row>
    </v-card>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import WekNewCard from "./comp/WekNewCard.vue";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBox,
    WekNewCard,
  },
  data() {
    return {
      selectedCategory: "",
      itemsCategory: [],
      wekNewContents: [],
      resData: [],
      reqData: modelService.reqDataModel(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
    };
  },
  mounted() {
    this.getWekNewData();
  },
  methods: {
    /** 데이터읽기  */
    async getWekNewData(categoryCd) {
      this.reqData.endpoint = "weknewContent";
      if (categoryCd !== undefined) {
        this.reqData.endpoint = `weknewContent/${categoryCd}`;
      }
      await this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP;
        if (0 < res.length) {
          if (categoryCd === undefined) {
            res = res.map((item, index) => ({
              ...item,
              text: item.categoryNm,
              value: item.categoryCd,
            }));
            this.itemsCategory = res;
          } else {
            res = res.map((item, index) => ({
              ...item,
              cardTitle: `${item.contentTitle}`,
              cardSubTitle: `${item.categoryNm}`,
              cardImg: `${item.thumbnail}`,
              hashTags: item.hashTag, //this.$helper.changeStringToArr(item.hashTag),
            }));
            this.wekNewContents = res;
          }
        }
        console.log("", res);
      });
    },
    changeSelectCategory(e) {
      console.log("e", e);
      this.getWekNewData(e);
    },
    clickCard(item) {
      // console.log("clickCard", item);
      this.$emit("clickCard", item);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
</style>