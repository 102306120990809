<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">{{ $t('menu.contents') }}</v-card-text>
    </v-col>
    <v-col cols="12">
      <v-tabs
        v-model="tabIdx"
        align-tabs="center"
        bg-color="deep-purple-accent-4"
        stacked
        @change="tabClick"
      >
        <v-tab> WekNew Contents </v-tab>
        <v-tab> doing Labs </v-tab>
        <v-tab> ~ </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabIdx">
        <WekNew ref="refWekNew" @clickCard="clickCard" />
      </v-tabs-items>
    </v-col>

    <!-- contents view -->
    <DialogWekNewContentViewer ref="refDialogWekNewContentViewer" />
  </v-row>
</template>
<script>
import WekNew from "./wekNew/WekNew.vue";
import DialogWekNewContentViewer from "@/components/commonV2/Dialog/DialogWekNewContentViewer.vue";
export default {
  components: {
    WekNew,
    DialogWekNewContentViewer,
  },
  data() {
    return {
      tabIdx: 0,
    };
  },
  methods: {
    tabClick(e) {},
    clickCard(item) {
      console.log("contents clickCard", item);
      this.$refs.refDialogWekNewContentViewer.Open(item);
    },
  },
};
</script>
<style lang="">
</style>